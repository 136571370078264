import Footer from '../../component/Footer'
import { Intro } from './Components/Intro'
import { Menu } from 'component/Menu'
import * as React from 'react'

const HomePage = () => {
  return (
    <>
      {new Array(200).fill(undefined).map(() => (
        <div className="star" />
      ))}

      <div id="wrapper" style={{ position: 'relative', zIndex: 1 }}>
        <div className="container">
          <Footer />
          <Menu />
          <Intro />
        </div>
      </div>
    </>
  )
}

export default HomePage
