import React from 'react'
const Contact = () => {
  return (
    <section className="row mt-5">
      <div className="col-md-6 col-sm-12 ">
        <span>Whatsapp</span>

        <p className="mt-2">
          <a href="https://wa.me/56942890535">(+56) 9 4289 0535</a>
        </p>
        <span className="mt-1">Correo</span>
        <p className="mt-2">
          <a href="mailto:contacto@umbracorp.cl">contacto@umbracorp.cl</a>
        </p>
      </div>
      <div className="col-md-6 col-sm-12">
        <span>Testimonios</span>

        <p>
          <em>
            "El equipo de UmbraCorp fue muy profesional y atento. El software
            que desarrollaron cumplió con todas nuestras expectativas."
            <br /> - Coca Cola Andina
          </em>
        </p>
        <p>
          <em>
            "Estoy muy satisfecho con los servicios de UmbraCorp. Me ayudaron a
            resolver un problema que tenía con mi negocio."
            <br /> - HBLED
          </em>
        </p>
        <p>
          <em>
            "UmbraCorp nos ayudó a mejorar nuestro negocio. El software que
            desarrollaron nos ha permitido automatizar procesos, mejorar la
            eficiencia y aumentar las ventas. Estamos muy satisfechos con los
            resultados."
            <br /> - RodenStock
          </em>
        </p>
      </div>
    </section>
  )
}
export default Contact
