import React from 'react'
import logo from '../assets/img/logo.png'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export const Menu = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar-dark ">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} width="180" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto ">
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/about">Nosotros</Nav.Link>
            <Nav.Link href="/services">Servicios</Nav.Link>
            <Nav.Link href="/contact">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
