import { Menu } from '../../component/Menu'
import * as React from 'react'
import Services from './Components/Services'
const ServicesPage = () => {
  return (
    <>
      {new Array(200).fill(undefined).map(() => (
        <div className="star" />
      ))}

      <div id="wrapper" style={{ position: 'relative', zIndex: 1 }}>
        <div className="container">
          <Menu />
          <Services />
        </div>
      </div>
    </>
  )
}
export default ServicesPage
