import React from 'react'
const About = () => {
  return (
    <section className="row mt-5">
      <div className="col-md-6 col-sm-12 ">
        <h3>¿Quiénes somos?</h3>

        <p className="mt-2">
          Somos UmbraCorp SPA, una empresa de desarrollo de software a medida
          fundada en 2018. Somos un equipo de profesionales apasionados por la
          tecnología y la innovación, y nos dedicamos a utilizarla para ayudar a
          las personas y a las empresas a alcanzar sus objetivos.
        </p>
        <h3 className="mt-1">¿Qué hacemos?</h3>
        <p className="mt-2">
          Ofrecemos una amplia gama de servicios de desarrollo de software,
          desde el análisis de necesidades hasta la implementación. Trabajamos
          con empresas de todos los tamaños y sectores para desarrollar software
          personalizado que se adapte a sus necesidades específicas.
        </p>
        <h3 className="mt-1">¿Cuáles son nuestros valores?</h3>
        <p>
          <ul>
            <li>
              Excelencia: Nos esforzamos por ofrecer los mejores servicios
              posibles a nuestros clientes.
            </li>
            <li>
              Compromiso: Estamos comprometidos con nuestros clientes y con sus
              objetivos.
            </li>
            <li>
              Transparencia: Somos transparentes con nuestros clientes sobre
              nuestro proceso de trabajo y nuestros costes.
            </li>
          </ul>
        </p>
        <h3>¿Por qué somos diferentes?</h3>

        <p>
          Creemos que lo que nos diferencia de otras empresas de desarrollo de
          software es nuestra atención al detalle, nuestro compromiso con la
          calidad y nuestro enfoque personalizado.
        </p>
        <h3>¿Qué podemos hacer por ti?</h3>

        <p>
          Si estás buscando una empresa de desarrollo de software que pueda
          ayudarte a alcanzar tus objetivos, no dudes en ponerte en contacto con
          nosotros. Estaremos encantados de ayudarte a desarrollar el software
          que necesitas.
        </p>
      </div>
      <div className="col-md-6 col-sm-12">
        <h3>Nuestro compromiso con la excelencia</h3>

        <p>
          Desde nuestros inicios, nos hemos comprometido a ofrecer los mejores
          servicios posibles a nuestros clientes. Para ello, contamos con un
          equipo de profesionales altamente cualificados y experimentados, que
          utilizan las últimas tecnologías y metodologías.
        </p>

        <h3>Nuestro compromiso con la transparencia</h3>

        <p>
          Somos transparentes con nuestros clientes sobre nuestro proceso de
          trabajo y nuestros costes. Queremos que nuestros clientes sepan lo que
          están recibiendo y lo que están pagando.
        </p>

        <h3>Nuestro enfoque personalizado</h3>

        <p>
          Creemos que cada cliente es único. Por eso, nos adaptamos a las
          necesidades específicas de cada uno. Trabajamos con nuestros clientes
          para desarrollar soluciones que se adapten a su negocio y a sus
          objetivos.
        </p>

        <h3>Nuestra experiencia</h3>

        <p>
          En los últimos cinco años, hemos trabajado con empresas de todos los
          tamaños y sectores. Hemos desarrollado software para empresas de
          retail, de servicios, de manufactura y de tecnología, entre otras.
        </p>

        <h3>Nuestros objetivos</h3>

        <p>
          Nuestro objetivo es ser la empresa de desarrollo de software a medida
          de referencia en Chile. Queremos ayudar a las empresas a alcanzar sus
          objetivos utilizando la tecnología como herramienta de transformación.
        </p>
      </div>
    </section>
  )
}
export default About
