import React from 'react'
import banner from '../../../assets/svg/undraw_firmware_re_fgdy.svg'
import TechFooter from '../../../component/TechFooter'
export const Intro = () => {
  return (
    <section className="row mt-5">
      <div className="col-md-6 col-sm-12 ">
        <h2>Software a medida para potenciar tu negocio</h2>
        <small className="mt-1">
          El desarrollo de software como motor de tu éxito empresarial
        </small>
        <p className="mt-2">
          ¿Necesitas una solución personalizada para tu negocio? En UmbraCorp
          SPA, nos especializamos en el desarrollo de software a medida para
          satisfacer las necesidades únicas de cada cliente.
          <br /> Con nuestro equipo de expertos en tecnología, podemos crear
          soluciones innovadoras y eficientes que mejorarán la productividad y
          el rendimiento de tu empresa. ¡Contáctanos hoy para comenzar a
          construir la solución perfecta para tu negocio!
        </p>
        <TechFooter />
      </div>
      <div className="col-md-6 col-sm-12">
        <img src={banner} className="intro-img" alt="banner" />
      </div>
    </section>
  )
}
