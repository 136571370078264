import * as React from 'react'
import s1 from '../assets/svg/github-142-svgrepo-com.svg'
import s2 from '../assets/svg/node-js-svgrepo-com.svg'
import s3 from '../assets/svg/react-svgrepo-com.svg'
import s4 from '../assets/svg/redux-svgrepo-com.svg'
import s5 from '../assets/svg/python-svgrepo-com.svg'
import s6 from '../assets/svg/mariadb-svgrepo-com.svg'
import s7 from '../assets/svg/mysql-svgrepo-com.svg'
import s8 from '../assets/svg/aws-lambda-svgrepo-com.svg'
import s9 from '../assets/svg/aws-svgrepo-com.svg'
import s10 from '../assets/svg/aws-dynamodb-svgrepo-com.svg'
import s11 from '../assets/svg/figma-svgrepo-com.svg'
const TechFooter = () => {
  return (
    <nav className="my-3 techbar">
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
        }}
      >
        {[s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11].map(s => (
          <li style={{ float: 'left' }}>
            <img src={s} alt="tech" />
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default TechFooter
