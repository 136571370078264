import React from 'react'
import './App.scss'
import './Stars.scss'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AboutPage from 'Pages/About/AboutPage'
import HomePage from 'Pages/Home/HomePage'
import ContactPage from 'Pages/Contact/ContactPage'
import ServicesPage from 'Pages/Services/ServicesPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'about',
    element: <AboutPage />,
  },
  {
    path: 'services',
    element: <ServicesPage />,
  },
  {
    path: 'contact',
    element: <ContactPage />,
  },
])
const App = () => {
  return <RouterProvider router={router} />
}

export default App
