import React from 'react'
import whatsappIcon from '../assets/svg/whatsapp-color-svgrepo-com.svg'
const Footer = props => {
  return (
    <footer>
      <span className="footerBtns">
        <a href="https://wa.me/56942890535">
          <img src={whatsappIcon} alt='whatsapp' />
        </a>
      </span>
      <span className="footerText">
        <a href="https://wa.me/56942890535">(+56) 9 4289 0535</a>
      </span>
      <span className="footerText">
        <a href="mailto:contacto@umbracorp.cl">contacto@umbracorp.cl</a>
      </span>
    </footer>
  )
}
export default Footer
