import React from 'react'
const Contact = () => {
  return (
    <section className=" mt-5">
      <h2>Servicios</h2>
      <div className="row mt-5 ">
        <div className="col-sm-12 col-md-4 p-1">
          <div className="service-box">
            <h3 className="py-1">Aplicaciones</h3>
            <p>
              Nos especializamos en el desarrollo personalizado de aplicaciones
              móviles para Android e iOS, enfocados en proporcionar la
              excelencia en experiencia de usuario, usabilidad, funcionalidad y
              diseño.
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 p-1">
          <div className="service-box">
            <h3 className="py-1">Sitios WEB</h3>
            <p>
              Implementamos soluciones adaptadas a las necesidades de tu
              proyecto y negocio, empleando tecnología de vanguardia en el
              desarrollo de aplicaciones web.
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 p-1">
          <div className="service-box">
            <h3 className="py-1">OutSourcing</h3>
            <p>
              Optimiza tu negocio: subcontrata el desarrollo de software a
              medida con expertos en tecnología. ¡Concentra recursos en tu core
              business y deja que nosotros nos encarguemos del desarrollo!.
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 p-1">
          <div className="service-box">
            <h3 className="py-1">Consultoría</h3>
            <p>
              Ofrecemos servicios de mentoría para impulsar la mejora continua y
              progresiva en proyectos comerciales, proporcionando orientación
              especializada en desarrollo de software para asegurar su éxito.
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 p-1">
          <div className="service-box">
            <h3 className="py-1">Diseño UX/UI</h3>
            <p>
              Contamos con un equipo de diseñadores altamente cualificados que
              se dedican a comprender las necesidades de los usuarios y a crear
              interfaces intuitivas y atractivas, garantizando así una
              experiencia de usuario óptima.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Contact
